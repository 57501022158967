import React, { useEffect, useRef, useState } from "react";
import { Text, ContactText } from "./styles";
import WhiteBorderTop from "../WhiteBorderTop";

const Contacts = () => {
  return (
    <div className="contacts-container">
      <ContactText style={{ color: "#848484" }}>Arbid Biz</ContactText>
      <ContactText style={{ color: "#848484", textDecoration: "none" }}>
        <a
          href="mailto:jimmy@arbid.com.br"
          target="_blank"
          style={{ borderBottom: "none" }}
          rel="noopener noreferrer"
        >
          jimmy@arbid.com.br
        </a>
      </ContactText>
      <ContactText style={{ color: "#848484" }}>
        <a
          href="tel:+5583988890808"
          target="_blank"
          style={{ borderBottom: "none" }}
          rel="noopener noreferrer"
        >
          +55 83 98889 0808
        </a>
      </ContactText>
      <ContactText style={{ color: "#848484" }}>
        Joao Pessoa, Brazil
      </ContactText>
    </div>
  );
};

export default function Tabs({ tabs, setActiveTab, activeTab, isMenuClosing }) {
  const [animatedBorderContainerHeight, setAnimatedBorderContainerHeight] =
    useState(0);
  const animatedContainerRef = useRef(null);

  useEffect(() => {
    if (animatedContainerRef.current) {
      if (isMenuClosing) {
        setAnimatedBorderContainerHeight("0%");
      } else {
        setTimeout(() => {
          setAnimatedBorderContainerHeight("calc(100% - 15px)");
        }, 300);
      }
    }
    // eslint-disable-next-line
  }, [animatedContainerRef.current, isMenuClosing]);

  return (
    <>
      <div className="hide-mobile menu-tabs">
        <div
          className="animated-border-container"
          ref={animatedContainerRef}
          style={{
            height: `${animatedBorderContainerHeight}`,
            borderColor: "black",
          }}
        />
        {tabs.map((tab, idx) => (
          <div
            onClick={() => {
              setActiveTab(tab);
              window.scroll(0, 0);
            }}
            style={{ zIndex: 1, position: "relative" }}
            className={`bold-hover ${activeTab === tab ? "bold-active" : ""}`}
            key={tab}
          >
            <Text
              className={`bold-hover ${activeTab === tab ? "bold-active" : ""}`}
            >
              {tab}
            </Text>
          </div>
        ))}
        <Contacts />
      </div>

      <div
        // onClick={() => {
        //   if (window.innerWidth > 750) {
        //     scrollToElement();
        //     setTimeout(() => {
        //       setMenuOpen(true);
        //     }, 800);
        //   } else {
        //     setMenuOpen(true);
        //   }
        // }}
        style={{ position: "relative" }}
        className="show-mobile about-menu-tile-margin"
        id="tab-menu-mobile"
      >
        {tabs.map((tab, idx) => (
          <>
            <Text
              style={{ color: "#000" }}
              key={tab}
              onClick={() => {
                setActiveTab(tab);
              }}
              className="down-arrow-mobile"
            >
              {tab}
              <WhiteBorderTop />
            </Text>
          </>
        ))}
        <Contacts />
      </div>
    </>
  );
}
