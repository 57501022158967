import React, { useEffect, useState, useRef } from "react";
// REACT ROUTER DOM
import { Link } from "react-router-dom";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { Close } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Container, Row } from "react-bootstrap";

// STYLED COMPONENTS
import {
  LogoContainer,
  Text,
  ButtonClose,
  GalleryName,
  BGabout,
  TextLogo,
  AboutContainer,
  LogoContainerMobile,
  GalleryNameMobile,
} from "./styles";
import { MenuContainer } from "../../pages/Home/styles";
// STYLES
import "./style.css";
import { trigger } from "../../storage/redux/about";
import { trigger as triggerBios } from "../../storage/redux/bio";
import { Fade as TabFade } from "../../pages/About/styles";
import Tabs from "../../components/Tabs/tabs";
import Collaboratori from "../../components/Tabs/collaboratori";
import Timeline from "../../components/Tabs/timeline";
import Associati from "../Tabs/associati";
import WhiteBorderTop from "../WhiteBorderTop";

function Menu(props) {
  const { project } = useSelector((state) => state.projects);
  const [openFilter, setOpenFilter] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [arrowDownColor, setArrowDownColor] = useState("#848484");
  const [arrowDownColor1, setArrowDownColor1] = useState("#848484");
  const refAssociati = useRef(null);
  const refCollaboratori = useRef(null);
  const refTimeline = useRef(null);
  const [tabsBorderHeight, setTabsBorderHeight] = useState(0);
  const [logoTop, setLogoTop] = useState(0);

  useEffect(() => {
    if (!openFilter) {
      //Scroll to top
      window.scrollTo(0, 0); // Keep an eye on this one
      //fade logo
      let el = document.querySelector(".fadeLogo");
      el.classList.add("fadeIn");
    }
  }, [openFilter]);

  // STATE HOOKS
  const [activeTab, setActiveTab] = useState(undefined);
  const [noAssociati, setNoAssociati] = useState(true);
  const [logoOpacity, setLogoOpacity] = useState(0);
  const [currentAssociati, setCurrentAssociati] = useState();
  // REDUX HOOKS
  const dispatch = useDispatch();
  const { about, bios } = useSelector((state) => state);

  const openGalleryNameMobile = () => {
    props.onWorkPress();
    return;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLogoOpacity(1);
    }, 800);

    if (props.sourcePage === "home") {
      const redirectTimeout = setTimeout(() => {
        window.location.href = "/work/gaggiorisanamentorustico";
      }, 2000);

      return () => {
        clearTimeout(redirectTimeout);
      };
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const el = document.getElementById("about-page");
    if (el) {
      el.scrollTop = 0;
    }
  }, [activeTab]);

  const aboutRef = useRef(<div />);

  const [animatedBorderContainerHeight, setAnimatedBorderContainerHeight] =
    useState(0);
  const [associatiBioBorderHeight, setAssociatiBioBorderHeight] = useState(0);
  const [isMenuClosing, setIsMenuClosing] = useState(false);
  const [isTabClosing, setIsTabClosing] = useState(false);
  const [aboutOpacity, setAboutOpacity] = useState(0);

  useEffect(() => {
    if (isMenuOpen && !activeTab) {
      setTimeout(() => {
        setAnimatedBorderContainerHeight("96vh");
      }, 500);
    } else {
      // setAnimatedBorderContainerHeight(0);
    }
  }, [activeTab, isMenuOpen]);

  const tabs = [
    "Associati",
    "Collaboratori",
    "Studi di fattibilità",
    "Concorsi",
    "Realizzazioni",
    "Consulenze",
  ];

  useEffect(() => {
    dispatch(trigger());
    dispatch(triggerBios());
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);

    //fade logo
    let el = document.querySelector(".fadeLogo");
    el.classList.add("fadeIn");
  }, []);

  const getBiosTimeLine = (field) => {
    var data = bios.bios.find((bio) => bio.Bio_title === field);
    if (data) {
      var timeLine = [];
      data.title.map((title) => {
        timeLine.push({
          year: title.dates_description.dates,
          text: title.dates_description.description,
        });
        return 0;
      });
      return timeLine;
    }
    return [];
  };

  useEffect(() => {
    if (!noAssociati) {
      const containerElement = document.getElementById("associati-bio-text");
      if (containerElement) {
        // const containerHeight = containerElement.clientHeight - 145;
        const containerHeight = containerElement.clientHeight - 183;
        setAssociatiBioBorderHeight(containerHeight);
      }
    } else {
      setAssociatiBioBorderHeight(0);
    }
  }, [noAssociati, currentAssociati]);

  const displayTab = () => {
    return (
      <div style={{ position: "relative" }}>
        {activeTab && (
          <div
            className="tab-border"
            id="tab-border"
            style={{ height: `${tabsBorderHeight}px` }}
          />
        )}
        {activeTab === "Associati" && (
          <TabFade in={activeTab === "Associati"} id="associati-tabfade">
            <div
              className="associati-bio-border"
              id="associati-bio-border"
              style={{
                opacity: noAssociati ? 0 : 1,
                height: `calc(${associatiBioBorderHeight}px - 25vh)`,
              }}
            />
            <div>
              <Associati
                ref={refAssociati}
                noAssociati={noAssociati}
                setNoAssociati={setNoAssociati}
                about={about}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
                currentAssociati={currentAssociati}
                setCurrentAssociati={(value) => {
                  setCurrentAssociati(value);
                }}
              />
            </div>
          </TabFade>
        )}
        {activeTab === "Collaboratori" && (
          <TabFade in={activeTab === "Collaboratori"}>
            <div>
              {" "}
              <Collaboratori
                ref={refCollaboratori}
                isMenuClosing={isMenuClosing}
                about={about}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}
        {activeTab === "Studi di fattibilità" && (
          <TabFade in={activeTab === "Studi di fattibilità"}>
            <div>
              {" "}
              <Timeline
                ref={refTimeline}
                timeline={getBiosTimeLine(activeTab)}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}
        {activeTab === "Concorsi" && (
          <TabFade in={activeTab === "Concorsi"}>
            <div>
              {" "}
              <Timeline
                timeline={getBiosTimeLine(activeTab)}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}
        {activeTab === "Realizzazioni" && (
          <TabFade in={activeTab === "Realizzazioni"}>
            <div>
              {" "}
              <Timeline
                timeline={getBiosTimeLine(activeTab)}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}
        {activeTab === "Consulenze" && (
          <TabFade in={activeTab === "Consulenze"}>
            <div>
              {" "}
              <Timeline
                timeline={getBiosTimeLine(activeTab)}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                isTabClosing={isTabClosing}
              />
            </div>
          </TabFade>
        )}
      </div>
    );
  };

  const handleMenuClose = () => {
    setIsMenuClosing(true);
    setTimeout(() => {
      setIsMenuOpen(false);
      setLogoTop(0);
      setActiveTab(undefined);
      setNoAssociati(true);
    }, 500);
  };

  const handleMenuOpen = () => {
    setLogoTop(-100);
    setTimeout(() => {
      setIsMenuClosing(false);
      setIsMenuOpen(true);
    }, 100);
    setTimeout(() => {
      setAboutOpacity(1);
    }, 200);
  };

  const handleMobileMenuOpen = () => {
    if (!activeTab) {
      setAnimatedBorderContainerHeight(0);
      setAboutOpacity(0);
    } else {
      setAboutOpacity(0);
      setTimeout(() => {
        setAboutOpacity(1);
      }, 500);
    }
    setTimeout(() => {
      if (!noAssociati) {
        setNoAssociati(true);
        setActiveTab(undefined);
        return;
      }
      if (activeTab) {
        setActiveTab(undefined);
        return;
      }
      setIsMenuOpen(false);
    }, 500);

    setTimeout(() => {
      setLogoTop(0);
    }, 500);
  };

  const handleActiveTab = (tab) => {
    // setAboutOpacity(0);
    setTimeout(() => {
      // setAboutOpacity(1);
      if (activeTab) {
        if (tab === "Associati") {
          setNoAssociati(true);
        }
        setActiveTab(tab);
      } else {
        setIsTabClosing(false);
        if (tab === "Associati") setNoAssociati(true);
        setActiveTab(tab);
      }
    }, 500);

    setTimeout(() => {
      const containerElement =
        document.getElementsByClassName("associati-row")[0];
      if (containerElement) {
        const containerHeight =
          containerElement.getBoundingClientRect().top +
          containerElement.clientHeight +
          15;
        setTabsBorderHeight(containerHeight);
      }
    }, 600);
  };

  if (props.page === "about") {
    return (
      <>
        <div className="about-centering">
          <ButtonClose onClick={() => props.goBack()}>
            <Close
              style={{
                position: "fixed",
                color: "#5a5a5a",
                top: "20px",
                left: "26px",
                width: "18px",
                cursor: "pointer",
                zIndex: 332,
              }}
            />
          </ButtonClose>
        </div>

        <Container close className="w-md-100 fadeLogo decoration">
          {window.location.pathname !== "/about" ? (
            <Link to="/about">
              <Text black>Piero Conconi Architetto</Text>
            </Link>
          ) : !props.hideNavbar ? (
            <Text className="gray-text-mobile" black>
              Piero Conconi Architetto
            </Text>
          ) : null}
        </Container>
        {!props.hideNavbar && (
          <>
            {" "}
            <BGabout id="bg-about" />
            <BGabout id="bg-about" />
          </>
        )}
      </>
    );
  }

  const LogoText = () => (
    <div onClick={handleMenuOpen} style={{ display: "flex" }}>
      <div
        style={{
          padding: "6px 17px 5px 6px",
          borderLeft: `1px solid ${props?.color || "#000"}`,
        }}
        id="p-logo"
      >
        <TextLogo style={{ color: props?.color || "#000" }}>P</TextLogo>
      </div>
      <div
        style={{
          borderLeft: `1px solid ${props?.color || "#000"}`,
          padding: "6px 34px 5px 6px",
        }}
      >
        <TextLogo style={{ color: props?.color || "#000" }}>C</TextLogo>
      </div>
      <div
        style={{
          borderLeft: `1px solid ${props?.color || "#000"}`,
          padding: "4px 17px 5px 6px",
        }}
      >
        <MenuContainer
          onMouseEnter={() => setArrowDownColor("#000")}
          onMouseLeave={() => setArrowDownColor("#848484")}
        >
          <TextLogo style={{ color: props?.color || "#000" }}>
            Architetti
          </TextLogo>
          {props?.sourcePage !== "home" ? (
            <KeyboardArrowDownIcon
              id="arrow-down-menu"
              style={{
                color: arrowDownColor,
                paddingBottom: "1px",
                paddingTop: "1px",
                // marginLeft: "6px",
              }}
            />
          ) : (
            ""
          )}
        </MenuContainer>
      </div>
    </div>
  );

  const menuContent = (
    <LogoContainer
      id="logo-container"
      className="w-md-100 fadeLogo decoration"
      style={{
        transition: "transition 0.5s all",
        top: `${logoTop}px`,
      }}
    >
      <LogoText />
    </LogoContainer>
  );

  const menuContentMobile = (
    <>
      {/* <div
        style={{
          background: "#f0f0f0",
          width: "100vw",
          height: 109,
          position: "fixed",
          zIndex: 20,
        }}
      ></div> */}
      <LogoContainerMobile
        id="logo-container-mobile"
        style={{
          transition: "transition 0.5s all",
          top: `${logoTop}px`,
        }}
        onClick={() => {
          setOpenFilter(!openFilter);
        }}
      >
        <LogoText />
        {props.sourcePage !== "home" && !logoTop && (
          <WhiteBorderTop option={2} />
        )}
      </LogoContainerMobile>

      {props.sourcePage !== "home" && (
        <>
          <GalleryNameMobile
            onClick={openGalleryNameMobile}
            black
            id="gallery-name-mobile"
            opacity={logoTop ? 0 : 1}
          >
            <p
              style={{
                whiteSpace: "nowrap",
                width: "90%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                margin: 0,
              }}
            >
              {project.name}
            </p>
            <WhiteBorderTop option={2} />
          </GalleryNameMobile>
        </>
      )}
    </>
  );

  return (
    <>
      <div
        className="background-nav-menu"
        id="fade-logo-container"
        style={{ opacity: logoOpacity }}
      >
        {props?.sourcePage === "home" ? (
          <Link to="/work/gaggiorisanamentorustico">
            {menuContent}
            {menuContentMobile}
          </Link>
        ) : (
          <>
            {menuContent}
            {menuContentMobile}
            <Link
              onClick={() => {
                setOpenFilter(!openFilter);
              }}
              to="/about"
              id="openFilterEl"
              style={{ position: "absolute", top: "-100px" }}
            >
              <Text className="marginLeftMobile">Piero Conconi Architetto</Text>
            </Link>
            {isMenuOpen && (
              <AboutContainer>
                <ButtonClose
                  onClick={() => handleMenuClose()}
                  className="close-button"
                >
                  <Close
                    style={{
                      position: "fixed",
                      color: "#5a5a5a",
                      top: "20px",
                      left: "26px",
                      width: "18px",
                      cursor: "pointer",
                      zIndex: 332,
                    }}
                  />
                </ButtonClose>
                <>
                  <div
                    style={{
                      overflowX: "hidden",
                      transition: "opacity 0.5s ease",
                      opacity: aboutOpacity,
                    }}
                    className="aboutPage"
                    id="about-page"
                  >
                    <div className="about-text-columns-wrapper about-wrapper-z-index">
                      <Container ref={aboutRef} fluid className=" pb-4">
                        <Row>
                          <div
                            id="tab-element"
                            className="col-about-tab pt-20 about-sticky-menu-header"
                            style={{ marginLeft: "130px !important" }}
                          >
                            <Tabs
                              tabs={tabs}
                              setActiveTab={(e) => handleActiveTab(e)}
                              isMenuClosing={isMenuClosing}
                              activeTab={activeTab}
                            />
                          </div>
                          <div
                            className="border-left-fixed"
                            style={{ height: animatedBorderContainerHeight }}
                          ></div>
                          <div className="col-mattia-hack "> </div>
                          <div
                            style={{ position: "relative" }}
                            className="col-about-tab-content pt-20 noPadding about-content-z-index"
                          >
                            <ButtonClose id="tab-menu-close-mobile">
                              <Close
                                onClick={(e) => {
                                  handleMobileMenuOpen();
                                }}
                                style={{
                                  width: "18px",
                                  fill: "#848484",
                                }}
                              />
                            </ButtonClose>
                            {displayTab()}
                          </div>
                        </Row>
                      </Container>
                      <div style={{ paddingBottom: "100px" }}></div>
                    </div>
                  </div>
                </>
              </AboutContainer>
            )}
            <GalleryName
              onClick={props.onWorkPress}
              black
              className="hide-mobile"
              id="gallery-name"
              onMouseEnter={() => setArrowDownColor1("#000")}
              onMouseLeave={() => setArrowDownColor1("#848484")}
              style={{
                transition: "transition 0.5s all",
                top: `${logoTop + 19}px`,
                // transform: isMenuOpen ? "translateY(-250px)" : "none",
              }}
            >
              <span>{project.name}</span>
              <KeyboardArrowDownIcon
                style={{ color: arrowDownColor1, paddingBottom: "2px" }}
              />
            </GalleryName>
          </>
        )}
      </div>
    </>
  );
}

export default Menu;
