import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Text, TextTimeline } from "./styles";
import HtmlRender from "../../components/HtmlRender";
import external from "./../../assets/img/external.svg";
import WhiteBorderTop from "../WhiteBorderTop";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Timeline({ timeline, activeTab, setActiveTab, ref }) {
  const mobileScreens = useMediaQuery("(max-width: 768px)");

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);
  }, []);

  const insertArrow = (text) => {
    const first = text.slice(-5);
    const last = text.slice(0, -5);
    const img = `<img className='external-link-icon' src='${external}' alt='external url'/>`;

    return first + last + img;
  };

  return (
    <Container fluid className="w-md-100 associati-container" ref={ref}>
      {/* <div className="animated-border-container" ref={timelineAnimatedContainerRef} style={{height: `${animatedBorderContainerHeight}%`, transitionDuration:  `${timeline.length > 10 ? timeline.length > 30 ? 2.3 : 1 : 0.5}s`}} /> */}
      <Row className="associati-row" style={{ opacity: opacity }}>
        <Text
          className="mobile-tab-title associati-title"
          onClick={() => setActiveTab(undefined)}
        >
          {activeTab}
          <WhiteBorderTop />
        </Text>

        <div className="hack col-mattia"> </div>
        <div className="timeline-bio-text">
          {timeline.map((el, idx) => (
            <div
              key={"timeline " + idx + el.text}
              className={idx === 0 && "timeline-first-row"}
            >
              {idx === 0 && mobileScreens && (
                <img
                  className="external-link-icon"
                  src={external}
                  alt="external url"
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  justifyContent: "space-between",
                  // paddingBottom: idx === timeline.length - 1 ? 0 : 22,
                  // paddingTop: idx ? 0 : 12,
                  flex: 12,
                  // marginTop: idx ? 22 : 0,
                  paddingTop: "10px",
                  paddingBottom: "12px",
                }}
              >
                <div style={{ display: "flex", flex: 2, marginRight: "38px" }}>
                  <TextTimeline>{el.year}</TextTimeline>
                </div>
                <div
                  style={{ display: "flex", flex: 10 }}
                  className="normalize-bios-text-no-margin"
                >
                  <HtmlRender>
                    {idx === 0 && !mobileScreens
                      ? insertArrow(el.text)
                      : el.text}
                  </HtmlRender>
                </div>
              </div>
              {/* {idx < timeline.length - 1 && idx > 0 && (
                <hr
                  style={{
                    marginTop: 12,
                    marginBottom: 2,
                    borderBottom: "none",
                    borderTop: "none",
                  }}
                />
              )} */}
              {idx === 0 && <WhiteBorderTop />}
            </div>
          ))}
        </div>
      </Row>
    </Container>
  );
}
